import { Card, Row, Image, Divider } from "antd";
import _ from "lodash";
import { Player, ControlBar, ProgressControl } from "video-react";
import { useEffect, useState } from "react";
import useApi from "../hooks/useApi";

const CreativeCard = ({ data }) => {
  const { request } = useApi();
  const [bundle, setBundle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [player, setPlayer] = useState(null);

  const copy = (text) => navigator.clipboard.writeText(text);

  const fetchApp = async () => {
    setBundle(null);
    setLoading(true);
    const res = await request("GET", "bundle", {
      query: {
        id: data.app_uid,
      },
    }).catch((err) => {});
    if (_.get(res, "data.body.bundle")) {
      setBundle(res.data.body);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!player) return;
    player.volume = 0.4;
  }, [player]);

  useEffect(() => {
    data && data.app_uid && fetchApp();
  }, [data?.id]);
  return (
    <Card
      style={{ width: "100%" }}
      size="small"
      bordered={false}
      title={
        bundle && (
          <Row align="middle">
            <Image width={36} src={bundle.appInfo.icon} />
            <div
              style={{
                display: "flex",
                marginLeft: 10,
                flexDirection: "column",
              }}
            >
              <span level={4} style={{ fontSize: 14 }}>
                {bundle.appInfo.title}
              </span>
              <span style={{ fontSize: 8 }}>{bundle.appInfo.developer}</span>
            </div>
          </Row>
        )
      }
    >
      <div style={{ fontSize: "0.9em" }}>
        <a target="_blank" href={`https://admigo.io/apps/${bundle?.id}`}>
          Admigo
        </a>
        <Divider type="vertical" />
        <a
          target="_blank"
          href={`https://play.google.com/store/apps/details?id=${bundle?.bundle}`}
        >
          Google Play
        </a>
        <Divider type="vertical" />
        {bundle && (
          <a
            target="_blank"
            href={`https://www.youtube.com/results?search_query=${encodeURIComponent(
              bundle.appInfo.title
            )}+Android+gameplay`}
          >
            YouTube
          </a>
        )}
      </div>
      <div
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          overflowX: "scroll",
        }}
      >
        <Row justify="center" style={{ padding: 10, minHeight: 480 }}>
          {!loading && (
            <Player
              ref={(player) => {
                setPlayer(player);
              }}
              autoPlay
              playsInline
              fluid={false}
              height={480}
              poster={`https://video.admigo.io/${data.id.slice(0, 4)}/${
                data.id
              }.png`}
            >
              <source
                src={`https://video.admigo.io/${data.id.slice(0, 4)}/${
                  data.id
                }.mp4`}
                type="video/mp4"
              />
              <ControlBar autoHide={false} disableDefaultControls>
                <ProgressControl />
              </ControlBar>
            </Player>
          )}
        </Row>
        {/* <Row justify="center" style={{ padding: 10 }}>
          {!loading && (
            <video
              autoPlay
              muted={false}
              controls
              height={500}
              loop
              poster={`https://video.admigo.io/${data.id.slice(0, 4)}/${
                data.id
              }.png`}
              className="bg-cover object-cover"
            >
              <source
                src={`https://video.admigo.io/${data.id.slice(0, 4)}/${
                  data.id
                }.mp4`}
                type="video/mp4"
              />
            </video>
          )}
        </Row> */}
        <Row style={{ marginTop: 40 }}>
          {bundle?.appInfo.screenshots.map((screenshot, i) => (
            <Image
              style={{ paddingLeft: 4, paddingRight: 4 }}
              key={i}
              placeholder={<div></div>}
              src={screenshot}
            />
          ))}
        </Row>
      </div>
    </Card>
  );
};

export default CreativeCard;
