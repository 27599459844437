import React, { useEffect, useState } from "react";
import {
  TagsOutlined,
  AppstoreOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import Router from "./Router";
import { Layout, Menu } from "antd";
import { useAuthContext } from "./contexts/AuthContext";
const { Content, Footer, Sider } = Layout;

const menuItems = [
  {
    name: "Dashboard",
    route: "/",
    icon: <BarChartOutlined />,
    roles: ["admin", "user"],
  },
  {
    name: "Tasks",
    route: "/tasks",
    icon: <AppstoreOutlined />,
    roles: ["admin", "user"],
  },
  { name: "Tags", route: "/tags", icon: <TagsOutlined />, roles: ["admin"] },
];

const MainLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [items, setItems] = useState([]);

  const { user, signOut } = useAuthContext();
  useEffect(() => {
    if (!user) return;

    setItems(
      menuItems
        .map((item) => (item.roles.includes(user.role) ? item : null))
        .filter((x) => !!x)
        .map((item) => ({
          key: item.route,
          icon: item.icon,
          label: item.name,
        }))
    );
  }, [user]);

  return (
    <Layout hasSider>
      <Sider
        theme="light"
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div
          style={{
            height: 32,
            margin: 16,
            color: "orange",
            fontSize: 20,
          }}
        >
          admark
        </div>

        <Menu
          theme="light"
          mode="inline"
          style={{ flex: 1 }}
          selectedKeys={[location.pathname]}
          onSelect={({ key }) => {
            navigate(key);
          }}
          defaultSelectedKeys={["4"]}
          items={items}
        />
        <div
          style={{
            background: "#eaeaea",
            padding: 10,
            borderRadius: 4,
            margin: 10,
            marginTop: 40,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          {user?.login}
          <a style={{ marginLeft: 20 }} onClick={signOut}>
            Sign out
          </a>
        </div>
      </Sider>
      <Layout
        className="site-layout"
        style={{ backgroundColor: "#EAEAEA", marginLeft: 200 }}
      >
        <Content
          style={{
            margin: "0px 0px 0",
            padding: "10px 10px",
            minHeight: "100vh",
            overflow: "initial",
          }}
        >
          <Router />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Admark ©{new Date().getFullYear()} Created by Admigo Inc.
        </Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
