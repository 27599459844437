import { message, Button, Card, Tag, Row, Col, Typography, Space } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import _ from "lodash";
import styled from "styled-components";
import { useHotkeys } from "react-hotkeys-hook";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import moment from "moment";
import FeedItem from "../components/FeedItem";
import { useAuthContext } from "../contexts/AuthContext";
import useApi from "../hooks/useApi";
import useFeed from "../hooks/useFeed";
import Overlay from "../components/Overlay";

const ArrowWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-bottom: 4px;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background-color: #ff731d;
    opacity: 0.8;
  }
`;

const Feed = () => {
  const { taskId, type } = useParams();
  const { user } = useAuthContext();
  const { current, hasMore, apply, loading, next, previous } = useFeed(
    type,
    taskId
  );
  const [saving, setSaving] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [tags, setTags] = useState(null);
  const [item, setItem] = useState(null);
  const [original, setOriginal] = useState(null);
  const [task, setTask] = useState(null);
  const [editStats, setEditStats] = useState([]);
  useIdleTimer({
    timeout: 1000 * 60 * 8,
    onIdle: () => {
      message.info("Leaving by inactivity...");
      setRedirecting(true);
      setTimeout(() => {
        window.location.href = "/tasks";
      }, 2000);
    },
  });

  useEffect(() => {
    request("GET", "history_counter", {
      query: {
        username: user.login,
      },
    })
      .then((res) => {
        const stats = _.get(res, "data.body.feed", []);
        setEditStats(stats);
      })
      .catch((err) => {});
  }, [current]);

  useEffect(() => {
    setItem(current);
    setOriginal(current);
  }, [current]);
  useHotkeys(
    "E",
    () => {
      next();
    },
    [current]
  );
  useHotkeys(
    "Q",
    () => {
      previous();
    },
    [current]
  );
  const { request } = useApi();

  const fetchTask = async () => {
    const { data } = await request("GET", `markup_task`, {
      query: { id: taskId },
    });

    if (data?.status !== "success") {
      message.error("Failed to fetch task");
      return;
    }
    setTask(data.body);
    const tags = data.body.tags;

    const { data: tagData } = await request("GET", `tags`);
    if (tagData?.status !== "success") {
      message.error("Failed to fetch tags");
      return;
    }

    setTags(_.filter(tagData.body, (tag) => tags.includes(tag.name)));
  };

  const onDeleteTag = async (tagName, tagValue) => {
    let _tags = tags;
    const { data } = await request("POST", "tags_delete", {
      body: {
        tag_name: tagName,
        tag_value: tagValue,
      },
    });
    _tags = [..._tags.filter((tag) => tag.name != tagName), data.body];
    setTags(_tags);
  };

  const onSave = async () => {
    setSaving(true);

    const newAdmarkTags = _.pick(
      _.get(item, `admark_tags`, {}),
      _.map(tags, (tag) => tag.name)
    );
    const { data: response } = await request(
      "PATCH",
      task.type === "bundles" ? "bundle" : "creative",
      {
        query: { id: item.id },
        body: { admark_tags: newAdmarkTags },
      }
    );
    if (response?.status !== "success") {
      message.error("Failed to save bundle");
      return;
    } else {
      let _tags = tags;
      // check exixsting tags
      for (let key in newAdmarkTags) {
        const tag = _.find(tags, (tag) => tag.name === key);
        if (!tag) {
          continue;
        }
        const newTags = _.filter(
          newAdmarkTags[key],
          (option) => !(tag.options || []).includes(option)
        );
        // update tag
        const newTag = {
          ...tag,
          options: [...(tag.options || []), ...newTags],
        };
        if (newTags.length > 0) {
          const { data } = await request("POST", `tag`, {
            body: newTag,
          });
          if (data?.status !== "success") {
            message.error("Failed to update tag");
            return;
          }
          // setTags([...tags.filter((tag) => tag.name != newTag.name), newTag]);
          _tags = [..._tags.filter((tag) => tag.name != newTag.name), newTag];
        }

        const incObj = {};
        for (let tagName of newAdmarkTags[key]) {
          // if tag not in original item then increment
          if (!_.get(original, `admark_tags.${key}`, []).includes(tagName)) {
            incObj[`${tagName}`] = 1;
          }
        }
        for (let tagName of _.get(original, `admark_tags.${key}`, [])) {
          // if tag not in new item then decrement
          if (!newAdmarkTags[key].includes(tagName)) {
            incObj[`${tagName}`] = -1;
          }
        }

        if (Object.keys(incObj).length > 0) {
          const { data } = await request("POST", "tags_update_counter", {
            body: {
              tag_name: key,
              tag_update: incObj,
            },
          });
          // setTags([...tags.filter((tag) => tag.name != key), data.body]);
          _tags = [..._tags.filter((tag) => tag.name != key), data.body];
        }
      }
      message.success("Saved!", 1);
      apply({ ...item, admark_tags: newAdmarkTags });
      setTags(_tags);
      next();
    }
    setSaving(false);
  };

  useEffect(() => {
    fetchTask();
  }, []);

  if (!task) return null;
  return (
    <>
      <Row style={{ marginBottom: 4 }}>
        <Col span={6}>
          <ArrowWrapper onClick={previous}>
            <ArrowLeftOutlined />
            <Typography.Text keyboard>Q</Typography.Text>
          </ArrowWrapper>
        </Col>
        <Col span={12}>
          <div style={{ borderRadius: 4, padding: 6 }}>
            <Space size="large">
              <span>total: {_.sumBy(editStats, (x) => x.count) || "0"}</span>
              <span>
                today:{" "}
                {_.find(
                  editStats || [],
                  (x) => x.date == moment().format("YYYY-MM-DD")
                )?.count || "0"}
              </span>
            </Space>
          </div>
        </Col>
        <Col span={6}>
          <ArrowWrapper onClick={next}>
            <Typography.Text keyboard>E</Typography.Text>
            <ArrowRightOutlined />
          </ArrowWrapper>
        </Col>
      </Row>
      <Overlay text={"Loading..."} show={loading || saving || redirecting} />
      <div>
        <FeedItem
          type={task.type}
          onNext={next}
          onPrevious={previous}
          tags={tags}
          item={item}
          markupTask={task}
          onChange={setItem}
          onDeleteTag={onDeleteTag}
          onSave={onSave}
        />
      </div>
    </>
  );
};

export default Feed;
