import React, { useState } from "react";
import _ from "lodash";
import { AutoComplete, Button, Input, Space, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useHotkeys, isHotkeyPressed } from "react-hotkeys-hook";

const TagsSelector = ({ item, onDeleteTag, tag, onChange }) => {
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(null);

  useHotkeys(
    "alt",
    () => setShowOptions(!showOptions),
    { keyup: true, keydown: true },
    [showOptions]
  );

  useEffect(() => {
    setOptions([
      {
        label: "Options",
        options: _.chain(tag?.options || [])
          .filter(
            (t) => !_.get(item, `admark_tags.${tag.suggestion}`, []).includes(t)
          )
          .filter(
            (t) => !_.get(item, `admark_tags.${tag.name}`, []).includes(t)
          )
          .map((t) => ({
            title: t,
            count: _.get(tag, `tags.${t}`, 0),
          }))
          .orderBy(["count"], ["desc"])
          .map((x) => renderItem(x.title, x.count))
          .value(),
      },
      {
        label: "AI Generated",
        options: _.chain(item)
          .get(tag.suggestion, [])
          .filter(
            (t) => !_.get(item, `admark_tags.${tag.name}`, []).includes(t)
          )
          .map((t) => ({
            title: t,
            count: _.get(tag, `tags.${t}`, 0),
          }))
          .orderBy(["count"], ["desc"])
          .map((x) => renderItem(x.title, x.count))
          .value(),
      },
    ]);

    if (
      !_.has(item, `admark_tags.${tag.name}`) &&
      _.get(item, tag.suggestion)
    ) {
      onChange(_.get(item, tag.suggestion, []));
    }
  }, [item, tag]);

  const onTagClose = (tagTitle) => {
    onChange(
      _.get(item, `admark_tags.${tag.name}`, []).filter((t) => t !== tagTitle)
    );

    if (showOptions) {
      onDeleteTag(tag, tagTitle);
    }
  };

  const onSelect = (value) => {
    onChange(_.uniq([..._.get(item, `admark_tags.${tag.name}`, []), value]));
    if (showOptions) {
    }
  };

  const onSearch = (search) => {
    const addNewValues = [];

    let hasSameTag = false;
    for (let option of options) {
      for (let o of option.options) {
        if (o.value?.toLowerCase() == search.toLowerCase()) {
          hasSameTag = true;
          break;
        }
      }
    }

    if (!hasSameTag) {
      addNewValues.push({
        label: "Add New",
        options: [renderItem(search, "new")],
      });
    }

    if (search) {
      setFilteredOptions([
        ...addNewValues,
        ..._.map(options, (option) => ({
          ...option,
          options: option.options.filter((o) => {
            return o.value?.toLowerCase().includes(search.toLowerCase());
          }),
        })),
      ]);
    } else {
      setFilteredOptions(null);
    }
  };

  return (
    <>
      <Space size={[2, 8]} wrap style={{ marginTop: 10, marginBottom: 10 }}>
        {_.get(item, `admark_tags.${tag.name}`, []).map((tagTitle) => {
          return (
            <Tag
              key={tagTitle}
              style={{
                fontWeight: "bold",
                textAlign: "center",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
              color={showOptions ? "#f50" : null}
              closable
              onClick={() => onTagClose(tagTitle)}
              onClose={() => onTagClose(tagTitle)}
            >
              {tagTitle}
              <span style={{ fontSize: "1em", color: "gray" }}>
                {_.has(tag?.tags, tagTitle) && tag.tags[tagTitle] > 0
                  ? ` (${tag.tags[tagTitle]})`
                  : ""}
              </span>
            </Tag>
          );
        })}
      </Space>
      <div>
        <AutoComplete
          dropdownMatchSelectWidth={280}
          style={{ width: 280 }}
          onSelect={onSelect}
          backfill
          onSearch={onSearch}
          options={filteredOptions || options}
          autoClearSearchValue
          allowClear
        >
          <Input size="small"></Input>
        </AutoComplete>
      </div>
    </>
  );
};

const renderItem = (title, count) => ({
  value: title,
  label: (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {title}
      <span style={{ color: "gray" }}>{count}</span>
    </div>
  ),
});

export default TagsSelector;
