import { useState } from "react";
import Login from "./pages/Login";
import Layout from "./Layout";
import { useAuthContext } from "./contexts/AuthContext";

function App() {
  const {isLogged} = useAuthContext();
  return <>
    {!isLogged && <Login />}
    {isLogged && <Layout />}
  </>;
}

export default App;
