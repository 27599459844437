import _ from "lodash";
import { Button, Card, Divider, List, Row, Tag } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import React, { useState, useEffect } from "react";
import useApi from "../hooks/useApi";
import { useAuthContext } from "../contexts/AuthContext";

const MarkupTasks = () => {
  const [tasks, setTasks] = useState([]);
  const { user } = useAuthContext();
  const { request } = useApi();
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();

  const toggleOptions = () => {
    if (!user) return;
    if (user.role != "admin") return;
    setShowOptions(!showOptions);
  };

  useHotkeys("alt", toggleOptions, [showOptions, user]);

  const fetchTasks = async () => {
    const { data } = await request("GET", "markup_tasks");
    
    let tasks = _.get(data, 'body', []);
    if (user && user.role != 'admin') {
      tasks = tasks.filter(task => task.type == 'bundles');
    }
    setTasks(tasks);
  };

  const deleteTask = async (id) => {
    await request("DELETE", `markup_task`, {
      query: { id },
    });
    fetchTasks();
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  // id: "63a1096f755219037b0d2f7c"
  // name: "App MarkupTask"
  // tags: ["genre", "rating"] (2)
  // type: "bundles"

  return (
    <div>
      <Card
        title="Markup tasks"
        extra={<Button onClick={() => navigate("/tasks/new")}>add new</Button>}
      >
        <List
          itemLayout="horizontal"
          size="small"
          dataSource={tasks}
          renderItem={(task) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <Link to={`/tasks/${task.id}`}>
                    <span>{task.name}</span>
                    <Tag
                      style={{ marginLeft: 10 }}
                      color={task.type === "bundles" ? "magenta" : "geekblue"}
                    >
                      {task.type}
                    </Tag>
                  </Link>
                }
              />
              <Row>
                <Link>Copy link</Link>
                <Divider type="vertical" />
                {showOptions && (
                  <>
                    <Link
                      style={{ color: "red" }}
                      onClick={() => deleteTask(task.id)}
                    >
                      Delete
                    </Link>
                    <Divider type="vertical" />
                  </>
                )}
                <Link to={`/feed/${task.type}/${task.id}`}>Feed</Link>
              </Row>
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
};

export default MarkupTasks;
