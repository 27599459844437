const Overlay = ({ text, show }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.25)",
        zIndex: 100,
        transform: "translateZ(0)",
        display: show ? "block" : "none",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          color: "#eaeaea",
          textShadow: "0 0 10px #000",
          transform: "translate(-50%, -50%)",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <h2>{text}</h2>
      </div>
    </div>
  );
};

export default Overlay;
