import { useState } from "react";
import {
  Card,
  Col,
  Divider,
  Tag,
  Image,
  Row,
  Typography,
  message,
  Button,
} from "antd";
import _ from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
import useApi from "../hooks/useApi";
import { useEffect } from "react";
import AppCard from "./AppCard";
import CreativeCard from './CreativeCard';
import TagsSelector from "./TagsSelector";

const FeedItem = ({
  item,
  onNext,
  onSave,
  onPrevious,
  markupTask,
  tags,
  type,
  onDeleteTag,
  onChange,
}) => {
  const [saving, setSaving] = useState(false);
  useHotkeys("s", () => onSave(), [saving, item]);
  if (!item || !tags) return null;

  const onDeleteTagClick = async (tag, tagTitle) => {
    message.loading({ content: "Deleting...", key: "delete" });

    const tagName = tag.name;
    const tagValue = tagTitle;
    await onDeleteTag(tagName, tagValue);

    message.destroy("delete");
  };

  return (
    <>
      <Row>
        <Col span={14}>
          {type === 'bundles' && (<AppCard data={item} />)}
          {type === 'creatives' && (<CreativeCard data={item} />)}
        </Col>

        <Col span={10} style={{ paddingLeft: 10 }}>
          <Card size="small" title={markupTask.name}>
            <Typography.Text code>
              {JSON.stringify(markupTask.condition, null, 2)}
            </Typography.Text>
          </Card>
          <Card
            size="small"
            title={
              <>
                Select tags <Typography.Text keyboard>ALT</Typography.Text>
              </>
            }
            style={{ marginTop: 10 }}
          >
            {_.orderBy(tags, [(x) => x.name]).map((tag, i) => (
              <div key={tag.name} style={{ marginTop: 10 }}>
                <span style={{ fontWeight: "bold", display: "block" }}>
                  {tag.description}
                </span>
                <TagsSelector
                  item={item}
                  tag={tag}
                  onDeleteTag={onDeleteTagClick}
                  onChange={(list) => {
                    onChange({
                      ...item,
                      admark_tags: {
                        ...(item?.admark_tags || {}),
                        [tag.name]: list,
                      },
                    });
                  }}
                ></TagsSelector>
              </div>
            ))}
            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button onClick={onSave} size="small" disabled={saving}>
                Save <Typography.Text keyboard>S</Typography.Text>
              </Button>
            </div>
          </Card>

          <Card
            size="small"
            disabled
            title="All Tags"
            style={{ marginTop: 40 }}
          >
            {_.map(item.admark_tags, (tags, category) => (
              <div key={category} style={{ marginTop: 20 }}>
                <div style={{ fontWeight: "bold", marginBottom: 8 }}>
                  {category}
                </div>
                <Row gutter={[0, 4]}>
                  {tags?.map((tag, i) => (
                    <Col key={i}>
                      <Tag style={{ fontWeight: "bold" }}>{tag}</Tag>
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default FeedItem;
