import _ from "lodash";
import { Button, Card, Divider, List, Row, Tag } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import React, { useState, useEffect } from "react";
import useApi from "../hooks/useApi";

const Tags = () => {
  const [tags, setTags] = useState([]);
  const { request } = useApi();
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();
  useHotkeys("alt", () => setShowOptions(!showOptions), [showOptions]);

  const fetchTags = async () => {
    const { data } = await request("GET", "tags");
    setTags(_.get(data, "body", []));
  };

  const deleteTag = async (id) => {
    await request("DELETE", "tag", {
      query: { id },
    });
    fetchTags();
  };

  useEffect(() => {
    fetchTags();
  }, []);

  // id: "63a1096f755219037b0d2f7c"
  // name: "App MarkupTask"
  // tags: ["genre", "rating"] (2)
  // type: "bundles"

  return (
    <div>
      <Card
        title="Tags"
        extra={<Button onClick={() => navigate("/tags/new")}>add new</Button>}
      >
        <List
          itemLayout="horizontal"
          size="small"
          dataSource={tags}
          renderItem={(tag, id) => (
            <List.Item key={id}>
              <List.Item.Meta
                title={
                  <Link to={`/tags/${tag.id}`}>
                    <span>{tag?.description || tag?.name}</span>
                  </Link>
                }
              />
              <Row>
                {showOptions && (
                  <>
                    <Link style={{color: 'red'}} onClick={() => deleteTag(tag.id)}>Delete</Link>
                    <Divider type="vertical" />
                  </>
                )}
              </Row>
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
};

export default Tags;
