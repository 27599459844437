import React, { useEffect, useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";

import styled from "styled-components";
import useApi from "../hooks/useApi";
import { useAuthContext } from "../contexts/AuthContext";

const Layout = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
`;

const Login = () => {
  const [form] = Form.useForm();
  const {loginByLogin} = useAuthContext();

  useEffect(() => {
    const savedLogin = localStorage.getItem("login");
    if (savedLogin) {
      form.setFieldValue("login", localStorage.getItem("login"));

    }
  }, []);

  const onFinish = async (values) => {
    message.loading({ content: "Loading...", key: "login" });
    const { login, password } = values;
    console.log(values)

    const success = await loginByLogin(login, password);
    if (!success) {
      message.error({ content: "Wrong login or password", key: "login", duration: 2 });
      return;
    }
    message.success({ content: "Success!", key: "login", duration: 2 });
  };

  return (
    <Layout>
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="login"
          rules={[{ required: true, message: "Please input your login!" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Login"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          shouldUpdate
          style={{ width: "100%", display: "flex", justifyContent: "end" }}
        >
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
            >
              Log in
            </Button>
          )}
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default Login;
