import { message } from "antd";
import { useEffect, useState } from "react";
import useApi from "./useApi";

let _loading = false;
const useFeed = (type, taskId) => {
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(null);
  const [cursor, setCursor] = useState(0);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const { request } = useApi();
  const [page, setPage] = useState(0);

  const fetchFeed = async () => {
    _loading = true;
    message.info("Fetching more...", 1);
    setLoading(true);

    const { data } = await request("GET", type, {
      query: {
        markup_task_id: taskId,
        length: 5,
        page: page,
        filled: 0
      },
    });
    _loading = false;
    setLoading(false);
    if (data?.status !== "success") {
      message.destroy("fetching");
      message.error("Error fetching feed");
      setHasMore(false);
      return;
    }

    if (data.body.length === 0) {
      setHasMore(false);
      message.destroy("fetching");
      return;
    }
    setPage(page + 1);
    setList((list) => [...list, ...data.body]);
    message.destroy("fetching");
  };

  useEffect(() => {
    if (!loading && !_loading && list.length == 0) {
      fetchFeed();
    }
  }, []);

  useEffect(() => {
    if (list.length == 0 || _loading) return;

    if (cursor >= list.length - 2 && hasMore) {
      fetchFeed();
    }
  }, [cursor]);

  useEffect(() => {
    setCurrent(list[cursor]);
  }, [cursor, list]);


  const apply = (data) => {
    list[cursor] = data;
  }
  const next = () => {
    setCursor((old) => old + 1 < list.length ? old + 1 : old);
     //(cursor + 1);
  };
  const previous = () => {
    setCursor((old) => (old < 1 ? old : cursor - 1));
  };

  return {
    current,
    apply,
    previous,
    next,
    loading,
    hasMore,
  };
};

export default useFeed;
