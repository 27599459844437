import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Editor from "@monaco-editor/react";
import { Form, Button, Input, Select, Row, Col, Card, message } from "antd";
import useApi from "../hooks/useApi";
import _ from "lodash";

const MarkupTask = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const { request } = useApi();
  const [data, setData] = useState({ id });
  const [tags, setTags] = useState([]);

  const fetchTags = async () => {
    const { data } = await request("GET", "tags");
    setTags(data.body);
  };

  const fetchTask = async () => {
    const { data } = await request("GET", "markup_task", {
      query: { id },
    });

    setData({
      ...data.body,
      conditionEdit: JSON.stringify(data.body.condition, null, 2),
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      conditionEdit: JSON.stringify(_.get(data, "condition", {}), null, 2),
    });
  }, []);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(_.chain(data).cloneDeep().omit("_stats").value());
    }
  }, [data]);

  const onSave = async (values) => {
    const newData = {
      ...values,
      condition: JSON.parse(values.conditionEdit || "{}"),
    };
    delete newData.conditionEdit;
    console.log("save =>", newData);

    if (newData.id === "new") {
      delete newData.id;
      const { data } = await request("POST", "markup_task", {
        body: newData,
      });
      if (data?.status == "success") {
        message.success("Saved");
        return navigate("/tasks");
      }
    }
    if (newData.id) {
      const id = newData.id;
      delete newData.id;
      const { data } = await request("PUT", "markup_task", {
        query: { id },
        body: newData,
      });

      if (data?.status == "success") {
        message.success("Saved");
        return navigate("/tasks");
      }
    }

    message.error("Save failed");
  };

  useEffect(() => {
    if (id && id !== "new") {
      fetchTask();
    }
    fetchTags();
  }, [id]);

  if (id !== "new" && !data) {
    return null;
  }

  return (
    <Card
      title={id == "new" ? "New markup task" : "Edit markup task"}
      style={{ width: "100%", maxWidth: 690, minWidth: 490, margin: "auto" }}
    >
      <Form
        style={{ marginTop: 30 }}
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSave}
        initialValues={data}
        autoComplete="off"
      >
        <Form.Item hidden requiredMark={false} name="id" label="ID"></Form.Item>
        <Form.Item required requiredMark={false} name="name" label="Name">
          <Input />
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: "Type required" }]}
          required
          requiredMark={false}
          name="type"
          label="Type"
        >
          <Select
            options={[
              { label: "Bundles", value: "bundles" },
              { label: "Creatives", value: "creatives" },
            ]}
          ></Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Condition required" }]}
          requiredMark={false}
          name="conditionEdit"
          label="Condition"
        >
          <Editor
            height={200}
            theme="vs-dark"
            sppellCheck={false}
            language="json"
          />
        </Form.Item>

        <Card title="Tags" size="small" style={{ width: "50%" }}>
          <Form.List
            name="tags"
            rules={[
              {
                validator: async (_, tags) => {
                  if (!tags || tags.length < 1) {
                    return Promise.reject(new Error("At least 1 tag required"));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    key={index}
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <Select
                      suffixIcon={
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          style={{ marginLeft: 8 }}
                          onClick={() => remove(field.name)}
                        />
                      }
                      options={tags.map((x) => ({
                        label: x.description,
                        value: x.name,
                      }))}
                    />
                  </Form.Item>
                ))}
                <Button
                  size="small"
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "60%" }}
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
                <Form.ErrorList errors={errors} />
              </>
            )}
          </Form.List>
        </Card>
        <Row style={{ marginTop: 40 }}>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Card>
  );
};

export default MarkupTask;
