import {
  Card,
  Col,
  Divider,
  Tag,
  Image,
  Row,
  Typography,
} from "antd";
import _ from "lodash";

const AppCard = ({ data }) => {
  const copy = (text) => navigator.clipboard.writeText(text);

  return (
    <Card
      size="small"
      bordered={false}
      title={
        <Row align="middle">
          <Image width={36} src={data.appInfo.icon} />
          <div
            style={{
              display: "flex",
              marginLeft: 10,
              flexDirection: "column",
            }}
          >
            <span level={4} style={{ fontSize: 14 }}>
              {data.appInfo.title}
            </span>
            <span style={{ fontSize: 8 }}>{data.appInfo.developer}</span>
          </div>
        </Row>
      }
    >
      <div style={{ fontSize: "0.9em" }}>
        <a onClick={() => copy(JSON.stringify(data, null, 2))}>Copy JSON</a>
        <Divider type="vertical" />
        <a target="_blank" href={`https://admigo.io/apps/${data.id}`}>
          Admigo
        </a>
        <Divider type="vertical" />
        <a
          target="_blank"
          href={`https://play.google.com/store/apps/details?id=${data.bundle}`}
        >
          Google Play
        </a>
      </div>
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        {data.installs_short} installs
      </div>
      <div
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          overflowX: "scroll",
        }}
      >
        {data.appInfo.screenshots.map((screenshot, i) => (
          <Image
            style={{ paddingLeft: 4, paddingRight: 4 }}
            key={i}
            placeholder={<div></div>}
            src={screenshot}
          />
        ))}
      </div>
      <Typography.Paragraph style={{ padding: 4, marginTop: 10 }}>
        {data.appInfo.description}
      </Typography.Paragraph>
    </Card>
  );
};

export default AppCard;
