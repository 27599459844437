import { Button } from "antd";
import { Navigate, Route, Routes, Link } from "react-router-dom";
import Dashboards from "./pages/Dashboards";
import { Divider } from "antd";
import MarkupTasks from "./pages/MarkupTasks";
import MarkupTask from "./pages/MarkupTask";
import Feed from "./pages/Feed";
import Tags from "./pages/Tags";
import Tag from "./pages/Tag";

const BackWrapper = ({ url, children }) => (
  <div>
    <Link to={url}>
      <Button>Back</Button>
    </Link>
    <Divider />
    {children}
  </div>
);

const Router = () => (
  <Routes>
    <Route path="/" element={<Dashboards />} />
    <Route path="/tasks" element={<MarkupTasks />} />

    <Route
      path="/tasks/:id"
      element={
        <BackWrapper url="/tasks">
          <MarkupTask />
        </BackWrapper>
      }
    />
    <Route path="/tags" element={<Tags />} />
    <Route path="/tags/:id" element={<Tag />} />
    <Route path="/feed/:type/:taskId" element={<Feed />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export default Router;
