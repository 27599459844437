import _ from "lodash";
import qs from "querystring";

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
if (ENDPOINT[ENDPOINT.length - 1] !== "/") {
  ENDPOINT += "/";
}

console.log("API ENDPOINT", ENDPOINT);

const useApi = () => {
  return {
    request: async (method, path, params) => {
      const body = _.get(params, "body", null);
      const query = _.get(params, "query", {});
      const customHeaders = _.get(params, "headers", {});

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...customHeaders,
      };

      if (token) {
        headers.Authorization = "Bearer " + token;
      }
      const q = query ? `?${qs.stringify(query)}` : "";
      const requestParams = {
        method,
        headers,
      };
      if (body) {
        requestParams.body = JSON.stringify(body);
      }
      const response = await fetch(
        `${ENDPOINT}${path}${q}`,
        requestParams
      ).catch((err) => {
        console.log("Error", err);
      });
      if (response.status === 401 && path !== "auth") {
        localStorage.removeItem("token");
        window.location.replace("/?loginAgain=true");
      }

      return { data: await response.json(), status: response.status };
    },
  };
};

export default useApi;
