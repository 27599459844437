import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Editor from "@monaco-editor/react";
import { Form, Button, Input, Select, Row, Card, message } from "antd";
import useApi from "../hooks/useApi";
import _ from "lodash";

const Tag = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const { request } = useApi();
  const [data, setData] = useState({ id });

  const fetchTag = async () => {
    const { data } = await request("GET", "tag", {
      query: { id },
    });

    setData(data.body);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(_.chain(data).cloneDeep().omit("_stats").value());
    }
  }, [data]);

  const onSave = async (values) => {
    const newData = _.cloneDeep(values);
    delete newData.conditionEdit;
    console.log("save =>", newData);

    if (newData.id === "new") {
      delete newData.id;
      const { data } = await request("POST", "tag", {
        body: newData,
      });
      if (data?.status == "success") {
        message.success("Saved");
        return navigate("/tags");
      }
    }
    if (newData.id) {
      const id = newData.id;
      delete newData.id;
      const { data } = await request("PUT", "tag", {
        query: { id },
        body: newData,
      });

      if (data?.status == "success") {
        message.success("Saved");
        return navigate("/tags");
      }
    }

    message.error("Save failed");
  };

  useEffect(() => {
    if (id && id !== "new") {
      fetchTag();
    }
  }, [id]);

  if (id !== "new" && !data) {
    return null;
  }

  return (
    <Card
      title={id == "new" ? "New tag" : "Edit tag"}
      style={{ width: "90%", maxWidth: 620, margin: "auto" }}
    >
      <Form
        style={{ marginTop: 30 }}
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSave}
        initialValues={data}
        autoComplete="off"
      >
        <Form.Item hidden requiredMark={false} name="id" label="ID"></Form.Item>
        <Form.Item required requiredMark={false} name="description" label="Description">
          <Input />
        </Form.Item>
        <Form.Item required requiredMark={false} name="name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="suggestion" label="Suggestion">
          <Input />
        </Form.Item>
        <Card title="Options" size="small" style={{ width: "50%" }}>
          <Form.List
            name="options"
            rules={[
              {
                validator: async (_, tags) => {
                  // if (!tags || tags.length < 1) {
                  if (false) {
                    return Promise.reject(new Error("At least 1 option required"));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    key={index}
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <Input
                      placeholder="tag name"
                      style={{ width: "90%" }}
                      suffix={
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          style={{ marginLeft: 8 }}
                          onClick={() => remove(field.name)}
                        />
                      }
                    />
                  </Form.Item>
                ))}
                <Button
                  size="small"
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "60%" }}
                  icon={<PlusOutlined />}
                >
                  Add option
                </Button>
                <Form.ErrorList errors={errors} />
              </>
            )}
          </Form.List>
        </Card>

        <Row style={{ marginTop: 40 }}>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Card>
  );
};

export default Tag;
