import { useEffect, useState } from "react";
import _ from "lodash";
import constate from "constate";
import useApi from "../hooks/useApi";
import parseJWT from "../utils/parseJWT";

const useAuth = () => {
  const { request } = useApi();

  const [user, setUser] = useState(parseJWT(localStorage.getItem("token")));
  const [isLogged, setIsLogged] = useState(!!localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);

  const checkTokenExpire = () => {
    if (!isLogged || !user) return;
    if (user.exp > Date.now() / 1000) return;
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    if (!isLogged) return;
    const explanation = parseJWT(localStorage.getItem("token"));
    if (!explanation /*|| explanation.exp < Date.now() / 1000*/) {
      flushToken();
      localStorage.removeItem("token");
      window.location.replace("/");
      return;
    }
    setUser(explanation);
    console.log("jwt =>", explanation);
  };

  const loginByLogin = async (login, password) => {
    setLoading(true);
    const response = await request("POST", "login", {
      body: { login, password },
    });
    console.log("loginByLogin =>", loading, password);

    setLoading(false);
    if (_.get(response, "data.status") === "success") {
      localStorage.setItem("token", response.data.body.jwt_token);
      window.location.reload();
      return true;
    }
    return false;
  };

  const signOut = () => {
    flushToken();
    setIsLogged(false);
    setUser(null);
    window.location.reload();
  };

  const flushToken = () => {
    localStorage.removeItem("token");
  };

  return {
    isLogged: !!user,
    flushToken,
    loading,
    loginByLogin,
    user,
    signOut,
  };
};

const [AuthProvider, useAuthContext] = constate(useAuth);

export { AuthProvider, useAuthContext };
